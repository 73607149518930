<template>
  <v-container fluid fill-height class="py-1">
    <v-layout>
      <v-flex xs12 class="d-flex flex-column align-center justify-center">
        <img
          src="/logo/logo.png"
          alt="Logo Coop Shop"
          :style="{ height: '70px' }"
        />
        <v-form
          ref="form"
          class="w-100"
          v-model="valid"
          @submit.prevent.stop="handleSubmit"
        >
          <v-card class="elevation-0">
            <div class="text-h3 line-height-1 default--text text-center mt-6">
              {{ $t("login.title") }}
            </div>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-text-field
                color="primary"
                v-model="email"
                :error-messages="errors"
                type="email"
                :label="$t('login.email')"
                autocomplete="off"
                required
                :rules="emailRules"
                dense
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                color="primary"
                v-model="password"
                :label="$t('login.password')"
                autocomplete="off"
                :append-icon="showPassword ? '$eye' : '$eyeOff'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                @click:append="toggleShowPassword"
                required
                dense
                outlined
                clearable
              ></v-text-field>

              <v-row
                no-gutters
                align="center"
                justify="center"
                justify-sm="space-between"
                class="mb-6"
              >
                <v-checkbox
                  color="default"
                  class="mt-0 pt-0"
                  hide-details
                  v-model="rememberMe"
                >
                  <template v-slot:label>
                    <div class="text-caption default--text font-weight-bold">
                      {{ $t("login.rememberOnDevice") }}
                    </div>
                  </template>
                </v-checkbox>
                <!-- <div class=""> -->
                <a
                  @click="goToResetPassword"
                  title="Hai dimenticato la password?"
                  aria-label="Hai dimenticato la password?"
                  class="text-decoration-underline text-caption font-weight-bold primary--text mt-6 mt-sm-0"
                  :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
                >
                  {{ $t("login.rememberPassword") }}
                </a>
                <!-- </div> -->
              </v-row>

              <!-- <div class="d-flex flex-column remember-password"> -->
              <v-btn
                :loading="loading"
                type="submit"
                color="primary"
                large
                block
                depressed
              >
                {{ $t("login.buttonLogin") }}
              </v-btn>
              <ResponseMessage :response="response" class="mt-3" />
              <!-- <v-btn
                    v-if="isCordova"
                    :loading="loadingCardCode"
                    class="mt-0 mb-1"
                    color="secondary"
                    large
                    block
                    depressed
                    @click="scanCard()"
                  >
                    {{ $t("login.buttonScanCard") }}
                  </v-btn> -->
              <!-- </div> -->
            </v-card-text>
            <v-divider v-if="enableRegistration" class="mx-4" />
            <v-card-actions
              v-if="enableRegistration"
              class="d-flex flex-column default--text pa-4"
            >
              <div class="text-h4 font-weight-600">
                {{ $t("login.messageRegister") }}
              </div>
              <v-btn
                @click="$emit('submit', false)"
                large
                outlined
                block
                depressed
                color="primary"
                :to="{ name: 'RegistrationUser', path: '/registration-user' }"
              >
                {{ $t("login.buttonRegister") }}
              </v-btn>
              <v-btn
                @click="$emit('submit', false)"
                :to="{ path: '/page/i-nostri-servizi/' }"
                large
                text
                block
                depressed
                class="mx-0 mt-2"
              >
                {{ $t("login.ourServices") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage";
// import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";

import pushNotification from "~/mixins/pushNotification";

import { mapActions, mapGetters } from "vuex";

// import SecurityService from "~/service/securityService";

import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "~/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideLogo: { type: Boolean, required: false, default: false },
    hideRegistration: { type: Boolean, required: false, default: false },
    redirectParams: { type: Object, required: false }
  },
  mixins: [pushNotification],
  components: { ResponseMessage },
  data() {
    return {
      loading: null,
      loadingCardCode: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digita la tua email"), isEmail()],
      passwordRules: [
        requiredValue("Digita la tua password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null,
      enableRegistration: process.env.VUE_APP_ENABLE_REGISTRATION == "true"
    };
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    // scanCard() {
    //   if (window.cordova && window.cordova.plugins.barcodeScanner) {
    //     try {
    //       this.loadingCardCode = true;
    //       // eslint-disable-next-line no-undef
    //       cordova.plugins.barcodeScanner.scan(
    //         result => {
    //           if (result) {
    //             this.loadingCardCode = false;
    //             // todo get card code and go to registration
    //           }
    //         },
    //         error => {
    //           this.loadingCardCode = false;
    //           console.error("Scanning failed", error);
    //         },
    //         {
    //           showFlipCameraButton: true,
    //           showTorchButton: true,
    //           resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    //           formats:
    //             this.formats ||
    //             "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
    //         }
    //       );
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // },
    async handleSubmit() {
      let _this = this;
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          if (_this.isAuthenticated) {
            global.EventBus.$emit("login");
            _this.$emit("loggedIn", true);
          }
          this.$emit("submit", true);
          if (_this.redirectParams) {
            _this.$router.push({
              name: _this.redirectParams.name,
              path: _this.redirectParams.path,
              params: _this.redirectParams.params
            });
          }
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
